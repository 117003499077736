<template>
  <div class="pdf-viewer-main">
    <PDFViewerMobile
      v-if="$vuetify.breakpoint.mobile"
      :url="url"
      :base64="base64"
      :filename="filename"
    ></PDFViewerMobile>
    <iframe
      v-else-if="base64"
      :src="`data:application/pdf;base64,${base64}`"
      type="application/pdf"
    ></iframe>
    <iframe v-else :src="url" type="application/pdf"></iframe>
  </div>
</template>
<script>
import PDFViewerMobile from '@/components/PDFViewerMobile'
export default {
  name: 'PDFViewer',
  components: { PDFViewerMobile },
  props: {
    filename: {
      type: String,
      default: 'documento',
    },
    url: {
      type: String,
    },
    base64: {
      type: String,
    },
  },
}
</script>
<style scoped>
.pdf-viewer-main {
  height: 100%;
}

.pdf-viewer-main iframe {
  /* min-height: calc(100vh - 200px); */
  height: 100%;
  width: 100%;
}
.pdf-viewer {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pdf-viewer >>> .v-card__text {
  background-color: #2a2a2e;
  overflow: scroll;
  flex-grow: 1;
  height: 100%;
  text-align: center;
}

.pdf-toolbar {
  margin-bottom: 10px;
}

.page-input {
  border: 1px solid;
  width: 20px;
  height: 20px;
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
</style>

<template>
  <v-card class="pdf-viewer">
    <v-toolbar elevation="0" dense>
      <!-- <v-toolbar-title>Title</v-toolbar-title> -->
      <v-btn @click="prev" small icon>
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn @click="next" small icon>
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <div class="pdf-pages">
        <v-form v-show="!loading" @submit.prevent="toPage">
          <button type="submit" hidden name="button"></button>
          <input
            v-model="page"
            type="number"
            min="1"
            :max="pageTotal"
            class="page-input"
          />
          /
          <span>{{ pageTotal }}</span>
        </v-form>
      </div>

      <v-spacer></v-spacer>

      <v-btn @click="zoomIn" small icon>
        <v-icon>mdi-magnify-plus-outline</v-icon>
      </v-btn>

      <v-btn @click="zoomOut" small icon>
        <v-icon>mdi-magnify-minus-outline</v-icon>
      </v-btn>
      <v-btn @click="download" small icon>
        <v-icon>mdi-download-outline</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <canvas ref="canvas"></canvas>
    </v-card-text>
  </v-card>
</template>
<script>
// import * as pdfjsLib from 'pdfjs-dist/build/pdf'
// import 'pdfjs-dist/build/pdf.worker.entry'

export default {
  name: 'PDFViewerMobile',
  props: {
    filename: {
      type: String,
      default: 'documento',
    },
    url: {
      type: String,
    },
    base64: {
      type: String,
    },
  },

  data: () => ({
    pdfDoc: null,
    rendering: false,
    loading: false,
    page: 1,
    pageTotal: 10,
    scale: 0.8,
    pageNumPending: null,
  }),

  mounted() {
    // console.log('mounted', pdfjsLib)
    // this.loadPDF()
    this.load()
  },

  methods: {
    _convertDataURIToBinary(dataURI) {
      const BASE64_MARKER = ';base64,'
      const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
      const base64 = dataURI.substring(base64Index)
      const raw = window.atob(base64)
      const rawLength = raw.length
      const array = new Uint8Array(new ArrayBuffer(rawLength))

      for (var i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i)
      }
      return array
    },

    download() {
      // const viewer = pdfjsLib.getViewer()
      this.pdfDoc.getData().then(data => {
        const pdfBlob = new Blob([data], { type: 'application/pdf' })
        const pdfUrl = URL.createObjectURL(pdfBlob)
        const downloadLink = document.createElement('a')
        downloadLink.href = pdfUrl
        downloadLink.download = `${this.filename}.pdf`
        downloadLink.click()
        URL.revokeObjectURL(pdfUrl)
      })
    },
    zoomIn() {
      this.scale = this.scale + 0.2
      this.queueRenderPage(this.page)
    },

    zoomOut() {
      this.scale = this.scale - 0.2
      this.queueRenderPage(this.page)
    },

    queueRenderPage(num) {
      if (this.rendering) {
        this.pageNumPending = num
      } else {
        this.renderPage(num)
      }
    },

    toPage() {
      this.page = parseInt(this.page)
      if (this.page > this.pageTotal || this.page < 1) {
        return
      }
      this.queueRenderPage(this.page)
    },

    prev() {
      if (this.page <= 1) {
        return
      }
      this.page--
      this.queueRenderPage(this.page)
    },

    next() {
      if (this.page >= this.pageTotal) {
        return
      }
      this.page++
      this.queueRenderPage(this.page)
    },

    renderPage(pageNumber) {
      this.rendering = true
      const canvas = this.$refs.canvas

      this.pdfDoc.getPage(pageNumber).then(page => {
        const viewport = page.getViewport({ scale: this.scale })
        canvas.height = viewport.height
        canvas.width = viewport.width

        const renderContext = {
          canvasContext: canvas.getContext('2d'),
          viewport: viewport,
        }

        const renderTask = page.render(renderContext)
        // wait for rendering
        renderTask.promise.then(() => {
          this.rendering = false

          if (this.pageNumPending !== null) {
            // New page rendering is pending
            this.renderPage(this.pageNumPending)
            this.pageNumPending = null
          }
        })
      })

      // Update page counters
      this.page = pageNumber

      // Using promise to fetch the page
    },

    async load() {
      this.loading = true
      const pdfData = this.url || `data:application/pdf;base64,${this.base64}`

      // eslint-disable-next-line
      const pdfTask = pdfjsLib.getDocument(pdfData)

      this.pdfDoc = await pdfTask.promise
      this.pageTotal = this.pdfDoc.numPages

      // Initial/first page rendering
      this.renderPage(this.page)
      this.loading = false
    },
  },
}
</script>
<style scoped>
.pdf-viewer {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pdf-viewer >>> .v-card__text {
  background-color: #2a2a2e;
  overflow: scroll;
  flex-grow: 1;
  height: 100%;
  text-align: center;
}

.pdf-toolbar {
  margin-bottom: 10px;
}

.page-input {
  border: 1px solid;
  width: 20px;
  height: 20px;
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
</style>

<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="95%"
    content-class="report-dialog"
    scrollable
  >
    <!-- <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template> -->
    <!-- eslint-disable-next-line vue/no-unused-vars -->
    <template v-slot:activator="on">
      <slot name="open" :open="open"></slot>
    </template>
    <v-card :class="{ 'rounded-0': $vuetify.breakpoint.mobile }">
      <v-toolbar color="primary" dark elevation="1" dense>
        <v-toolbar-title>Laudo do exame</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-4">
        <v-container>
          <div class="content-header mb-4">
            <v-row>
              <v-col cols="12">
                Laudo
                <v-btn
                  small
                  type="button"
                  class="ma-1"
                  elevation="0"
                  @click="tab = 'report'"
                  :color="tab == 'report' ? 'primary' : 'default'"
                >
                  Laudo do exame
                </v-btn>
              </v-col>
            </v-row>
            <v-divider />
            <v-row>
              <v-col cols="12">
                Anexos
                <span v-show="loadingAttachments">...</span>
                <v-btn
                  v-show="!loadingAttachments"
                  small
                  v-for="(attachment, index) in attachments"
                  :key="index"
                  type="button"
                  @click.prevent="openAttachment(attachment)"
                  class="ma-1"
                  elevation="0"
                  :color="
                    tab == `attachment-tab-${attachment.id}`
                      ? 'primary'
                      : 'default'
                  "
                >
                  {{ attachment.name }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div class="content">
            <div class="tab-container" v-show="tab == 'report'">
              <p v-if="loadingReport">Carregando laudo...</p>
              <v-alert
                text
                outlined
                color="deep-orange"
                icon="mdi-alert-circle"
                v-else-if="showError"
              >
                {{ errorMsg }}
              </v-alert>
              <PDFViewer v-else :url="reportSRC()"></PDFViewer>
            </div>
            <div
              class="tab-container"
              v-for="(attachment, index) in attachments"
              :key="`attachment-tab-${index}`"
              v-show="tab == `attachment-tab-${attachment.id}`"
            >
              <p v-show="attachment.loading">Carregando anexo...</p>
              <div v-if="attachment.done" class="file-container">
                <v-alert
                  text
                  outlined
                  color="deep-orange"
                  icon="mdi-alert-circle"
                  v-if="attachment.error != null"
                >
                  {{ attachment.error }}
                </v-alert>
                <PDFViewer
                  v-else-if="
                    attachment.extension == 'pdf' && attachment.error == null
                  "
                  :base64="attachment.file"
                ></PDFViewer>
                <img
                  v-else-if="
                    allowedImageExtensions.includes(
                      attachment.extension.toLowerCase()
                    )
                  "
                  :src="imgSRC(attachment)"
                  :alt="attachment.original_name"
                />
                <v-btn
                  v-else
                  primary
                  :download="attachment.original_name"
                  target="_blank"
                  :href="fileSRC(attachment)"
                >
                  <v-icon dark class="mr-1"> mdi-cloud-download </v-icon>
                  Baixar arquivo
                </v-btn>
              </div>
            </div>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
import PDFViewer from '@/components/PDFViewer'
export default {
  name: 'ReportDialog',
  components: { PDFViewer },
  computed: {
    theme() {
      return this.$store.getters.theme
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      loadingReport: false,
      showError: false,
      errorMsg: null,
      attachments: [],
      tab: 'report',
      loadingAttachments: false,
      reportBase64: '',
      allowedImageExtensions: [
        'jpg',
        'jpeg',
        'jpe',
        'jif',
        'jfif',
        'jfi',
        'png',
        'gif',
        'webp',
        'tiff',
        'tif',
        'bmp',
        'dib',
        'jp2',
        'j2k',
        'jpf',
        'jpx',
        'jpm',
        'mj2',
        'svg',
        'svgz',
      ],
    }
  },

  props: ['lauIsn', 'padIsn', 'pacIsn', 'publicReport', 'viewer'],

  methods: {
    open() {
      if (this.lauIsn == null) {
        this.$toast.show('Laudo não liberado.', 'warn')
        return
      }
      this.dialog = true
      this.tab = 'report'
      // this.loading = true
      this.loadingReport = false
      this.loadingAttachments = false
      this.showError = false
      this.errorMsg = null
      this.attachments = []
      this.reportBase64 = []

      // $(this.$refs.modal).modal('show')
      this.getAttachments(this.padIsn)
      this.fetchReport(this.lauIsn)
    },

    hide() {
      // $(this.$refs.modal).modal('hide')
    },

    imgSRC(attachment) {
      let src = '#'
      // prevent Windows OS bug https://naja.teamwork.com/#/tasks/22311729
      const extension =
        attachment.extension == 'jpg' ? 'jpeg' : attachment.extension
      if (attachment.file != null) {
        src = `data:image/${extension};base64,${attachment.file}`
      }
      return src
    },

    fileSRC(attachment) {
      let src = '#'
      if (attachment.file != null) {
        src = `data:application/${attachment.extension};base64,${attachment.file}`
      }
      return src
    },

    pdfSRC(base64) {
      console.log('pdfSRC', base64 != null)
      let src = ''

      if (base64 != null) {
        const byteCharacters = window.atob(base64)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], { type: 'application/pdf' })
        src = window.URL.createObjectURL(blob)
      }
      return src
    },

    reportSRC() {
      let src = '#'
      if (this.reportBase64 != null) {
        const blob = new Blob([this.reportBase64], { type: 'application/pdf' })
        src = window.URL.createObjectURL(blob)
      }
      return src
    },

    fetchReport(isn) {
      this.loadingReport = true
      this.showError = false
      const viewer = this.viewer || 'portal'

      Api.medicalReportPDF(isn, this.pacIsn, this.publicReport, viewer)
        .then(response => {
          this.reportBase64 = response.data
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status == 404) {
              this.$toast.show('Laudo não encontrado', 'warn')
              this.errorMsg = 'Laudo não encontrado'
            } else {
              this.$toast.show('Erro ao carregar laudo', 'warn')
            }
          } else {
            throw error
          }
        })
        .finally(() => {
          this.loadingReport = false
        })
    },

    getAttachments(isn) {
      this.loadingAttachments = true

      Api.examAttachments({ isn: isn })
        .then(response => {
          this.attachments = response.data.map(att => ({
            id: att.isn_anexo,
            original_name: att.nome_arquivo,
            name: att.observacao || att.tipo_anexo.nome,
            loading: false,
            done: false,
            file: null,
            extension: att.nome_extensao_arquivo,
          }))
        })
        .catch(error => {
          if (error.response) {
            this.$toast.show('Erro ao buscar anexos', 'warn')
          } else {
            throw error
          }
        })
        .finally(() => {
          this.loadingAttachments = false
        })
    },

    openAttachment(attachment) {
      if (attachment.file == null || attachment.error != null) {
        attachment.loading = true
        Api.getAttachment(attachment.id)
          .then(response => {
            attachment.file = response.data.arquivo
            attachment.error = null
            attachment.extension = response.data.nome_extensao_arquivo
            attachment.done = true
          })
          .catch(error => {
            if (error.response) {
              this.$toast.show('Erro ao buscar anexo', 'warn')
            } else {
              throw error
            }
          })
          .finally(() => {
            attachment.loading = false
          })
      }

      this.tab = `attachment-tab-${attachment.id}`
    },
    //
    // save () {
    //   this.loading = true;
    //   Api.confirmSchedule(this.form).then(() => {
    //     this.$toast.show("Agendamento confirmado", "success");
    //     this.$emit('confirmed', this.form.isn);
    //     this.hide()
    //   }).catch((error) => {
    //     this.$toast.show("Erro ao confirmar agendamento", "error");
    //     throw error;
    //   }).finally(() => {
    //     this.loading = false;
    //   });
    // }
  },
}
</script>
<style>
.report-dialog {
  height: 100%;
}
</style>
<style lang="css" scoped>
.v-toolbar {
  flex: unset;
}

.v-card .container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex-grow: 1;
  overflow: hidden;
  height: 100%;
}

.tab-container,
.file-container {
  height: 100%;
}
.ui.fullscreen.modal {
  min-height: 95%;
}

.download h2 {
  margin-top: 20px;
  color: var(--button-primary-bg-color) !important;
}

.ui.mini.button {
  margin-bottom: 5px;
}

span.label {
  padding-right: 10px;
  padding-right: 20px;
}

.tab-container {
  text-align: center;
}

.tab-container img {
  max-width: 100%;
}

/* iframe {
  width: 100%;
  border: none;
  min-height: 600px;
} */

.ui.negative.message {
  max-width: 300px;
  margin: 0 auto;
  margin-top: 100px;
  text-align: center;
}

.report-contrainer {
  min-height: 200px;
  overflow: auto;
}

.mini.button {
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"max-width":"95%","content-class":"report-dialog","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(on){return [_vm._t("open",null,{"open":_vm.open})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{class:{ 'rounded-0': _vm.$vuetify.breakpoint.mobile }},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","elevation":"1","dense":""}},[_c('v-toolbar-title',[_vm._v("Laudo do exame")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"mt-4"},[_c('v-container',[_c('div',{staticClass:"content-header mb-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Laudo "),_c('v-btn',{staticClass:"ma-1",attrs:{"small":"","type":"button","elevation":"0","color":_vm.tab == 'report' ? 'primary' : 'default'},on:{"click":function($event){_vm.tab = 'report'}}},[_vm._v(" Laudo do exame ")])],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Anexos "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingAttachments),expression:"loadingAttachments"}]},[_vm._v("...")]),_vm._l((_vm.attachments),function(attachment,index){return _c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingAttachments),expression:"!loadingAttachments"}],key:index,staticClass:"ma-1",attrs:{"small":"","type":"button","elevation":"0","color":_vm.tab == `attachment-tab-${attachment.id}`
                    ? 'primary'
                    : 'default'},on:{"click":function($event){$event.preventDefault();return _vm.openAttachment(attachment)}}},[_vm._v(" "+_vm._s(attachment.name)+" ")])})],2)],1)],1),_c('div',{staticClass:"content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab == 'report'),expression:"tab == 'report'"}],staticClass:"tab-container"},[(_vm.loadingReport)?_c('p',[_vm._v("Carregando laudo...")]):(_vm.showError)?_c('v-alert',{attrs:{"text":"","outlined":"","color":"deep-orange","icon":"mdi-alert-circle"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_c('PDFViewer',{attrs:{"url":_vm.reportSRC()}})],1),_vm._l((_vm.attachments),function(attachment,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab == `attachment-tab-${attachment.id}`),expression:"tab == `attachment-tab-${attachment.id}`"}],key:`attachment-tab-${index}`,staticClass:"tab-container"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(attachment.loading),expression:"attachment.loading"}]},[_vm._v("Carregando anexo...")]),(attachment.done)?_c('div',{staticClass:"file-container"},[(attachment.error != null)?_c('v-alert',{attrs:{"text":"","outlined":"","color":"deep-orange","icon":"mdi-alert-circle"}},[_vm._v(" "+_vm._s(attachment.error)+" ")]):(
                  attachment.extension == 'pdf' && attachment.error == null
                )?_c('PDFViewer',{attrs:{"base64":attachment.file}}):(
                  _vm.allowedImageExtensions.includes(
                    attachment.extension.toLowerCase()
                  )
                )?_c('img',{attrs:{"src":_vm.imgSRC(attachment),"alt":attachment.original_name}}):_c('v-btn',{attrs:{"primary":"","download":attachment.original_name,"target":"_blank","href":_vm.fileSRC(attachment)}},[_c('v-icon',{staticClass:"mr-1",attrs:{"dark":""}},[_vm._v(" mdi-cloud-download ")]),_vm._v(" Baixar arquivo ")],1)],1):_vm._e()])})],2)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }